import React from "react";
import { Box, Typography, Stack, Grid, Link } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useScreenSize } from "../hooks/ScreenSizeContext";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import Footer from "../Components/Footer";

export const AvisoLegal = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { screenSize, pagewidth } = useScreenSize();

  const mainTitleStyle = {
    mt: "3rem",
    lineHeight: "3rem",
    fontWeight: "900",
    fontSize: "3rem",
    fontFamily: "Inter",
    color: colors.grey[900],
  };

  const subtitleStyle = {
    mt: "2rem",
    fontSize: "1.25rem",
    fontFamily: "Inter",
    color: colors.primary[500],
    fontWeight: "bold",
  };

  const textStyle = {
    mt: "1rem",
    fontSize: "1rem",
    fontFamily: "Inter",
    color: colors.grey[700],
  };

  const listItemTextStyle = {
    mt: "1rem",
    display: "block",
    paddingLeft: "1rem", // Indent the list item
    position: "relative",
    marginBottom: "0.25rem",
    fontSize: "1rem",
    fontFamily: "Inter",
    color: colors.grey[700],
    "&:before": {
      content: '"\\2022"', // Unicode for bullet
      position: "absolute",
      left: 0,
      top: -1,
      color: colors.grey[700], // Color of the bullet point
    },
  };
  const listItemTextStyleNext = {
    mt: "0rem",
    display: "block",
    paddingLeft: "1rem", // Indent the list item
    position: "relative",
    marginBottom: "0.25rem",
    fontSize: "1rem",
    fontFamily: "Inter",
    color: colors.grey[700],
    "&:before": {
      content: '"\\2022"', // Unicode for bullet
      position: "absolute",
      left: 0,
      top: -1,
      color: colors.grey[700], // Color of the bullet point
    },
  };

  const listItemTextStyle2 = {
    mt: "0.5rem",
    display: "block",
    paddingLeft: "3rem", // Indent the list item
    position: "relative",
    marginBottom: "0.25rem",
    fontSize: "1rem",
    fontFamily: "Inter",
    color: colors.grey[700],
    "&:before": {
      content: '"\\2022"', // Unicode for bullet
      position: "absolute",
      left: "2rem",
      top: -1,
      color: colors.grey[700], // Color of the bullet point
    },
  };
  const listItemTextStyle2Next = {
    mt: "0rem",
    display: "block",
    paddingLeft: "3rem", // Indent the list item
    position: "relative",
    marginBottom: "0.25rem",
    fontSize: "1rem",
    fontFamily: "Inter",
    color: colors.grey[700],
    "&:before": {
      content: '"\\2022"', // Unicode for bullet
      position: "absolute",
      left: "2rem",
      top: -1,
      color: colors.grey[700], // Color of the bullet point
    },
  };

  const listItemTextStyleNumber = {
    mt: "1rem",
    display: "block",
    paddingLeft: "0", // Indent the list item
    position: "relative",
    marginBottom: "0.25rem",
    fontSize: "1rem",
    fontFamily: "Inter",
    fontWeight: "500",
    color: colors.primary[500],
  };

  const listItemTextStyleNumberNext = {
    mt: "0rem",
    display: "block",
    paddingLeft: "1rem", // Indent the list item
    position: "relative",
    marginBottom: "0.25rem",
    fontSize: "1rem",
    fontFamily: "Inter",
    color: colors.grey[700],
  };

  const determinePadding = () => {
    switch (screenSize) {
      case "md":
        return "0rem 2rem 0rem 2rem";
      case "lg":
        return "0rem 2rem 0rem 2rem";
      default:
        return "0rem 1rem 0rem 1rem";
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          alignItems: "center",
          height: "100%",
          maxWidth: `${pagewidth}px`,
          margin: "auto",
          padding: determinePadding(),
        }}
      >
        <Stack>
          <Typography sx={mainTitleStyle}>
            Aviso Legal de Uso de la Plataforma
          </Typography>
          <Typography sx={subtitleStyle}>
            Identificación del responsable
          </Typography>
          <Typography sx={listItemTextStyle}>
            <strong>Nombre:</strong> Israel Llagostera García
          </Typography>
          <Typography sx={listItemTextStyleNext}>
            <strong>NIF:</strong> 46376430N
          </Typography>
          <Typography sx={listItemTextStyleNext}>
            <strong>Dirección:</strong> Carrer Esperanto 33, 08228, Barcelona,
            Cataluña, España.
          </Typography>
          <Typography sx={listItemTextStyleNext}>
            <strong>Número de Teléfono:</strong> +34 608469208
          </Typography>
          <Typography sx={listItemTextStyleNext}>
            <strong>Correo electrónico:</strong> israel.llagostera@byneural.ai
          </Typography>
          {/* --------------------------------------------------------------------------------------------------------------------- */}
          <Typography sx={subtitleStyle}>Propósito del sitio web</Typography>
          <Typography sx={textStyle}>
            El presente sitio web tiene como objetivo proporcionar una
            plataforma avanzada y accesible para la creación y gestión de
            asistentes virtuales, especialmente diseñada para agencias de
            marketing digital, diseño web y desarrollo web. A través de nuestra
            plataforma, ofrecemos herramientas y funcionalidades que permiten la
            personalización, gestión y optimización de asistentes virtuales,
            facilitando la generación de leads, la mejora de conversiones y el
            cumplimiento normativo. Nuestra misión es empoderar a las agencias
            con tecnología de inteligencia artificial de vanguardia para que
            puedan ofrecer un valor añadido significativo a sus clientes,
            optimizando procesos y mejorando la interacción con los usuarios
            finales.
          </Typography>
          {/* --------------------------------------------------------------------------------------------------------------------- */}
          <Typography sx={subtitleStyle}>Condiciones de Uso</Typography>
          <Typography sx={textStyle}>
            El acceso y uso del sitio web de byNeural y de la plataforma que
            ofrecemos están sujetos a las siguientes condiciones. Al acceder y
            utilizar nuestros servicios, el usuario acepta cumplir con estas
            condiciones.
          </Typography>
          <Typography sx={listItemTextStyleNumber}>
            1. Aceptación de las Condiciones
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
            Al utilizar este sitio web y los servicios de la plataforma, el
            usuario acepta las presentes Condiciones de Uso. Si no está de
            acuerdo con alguna de estas condiciones, debe abstenerse de utilizar
            el sitio y los servicios.
          </Typography>
          <Typography sx={listItemTextStyleNumber}>
            2. Modificación de las Condiciones
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
            byNeural se reserva el derecho a modificar estas Condiciones de Uso
            en cualquier momento. Las modificaciones serán efectivas desde el
            momento de su publicación en el sitio web. Se recomienda a los
            usuarios revisar periódicamente las Condiciones de Uso para estar
            informados de posibles cambios.
          </Typography>
          <Typography sx={listItemTextStyleNumber}>3. Uso Permitido</Typography>
          <Typography sx={listItemTextStyleNumberNext}>
            Los usuarios se comprometen a utilizar el sitio web y los servicios
            de manera legal y conforme a estas Condiciones de Uso. Queda
            prohibido:
          </Typography>
          <Typography sx={listItemTextStyle2}>
            Utilizar el sitio o los servicios para realizar actividades
            ilícitas, fraudulentas o no autorizadas.
          </Typography>
          <Typography sx={listItemTextStyle2Next}>
            Interferir con el funcionamiento del sitio web o de la plataforma,
            incluyendo, pero no limitado a, la transmisión de virus o cualquier
            otro código malicioso.
          </Typography>
          <Typography sx={listItemTextStyle2Next}>
            Acceder a áreas restringidas del sitio web sin autorización.
          </Typography>
          <Typography sx={listItemTextStyle2Next}>
            Utilizar los contenidos del sitio web con fines comerciales sin el
            consentimiento previo y por escrito de byNeural.
          </Typography>
          <Typography sx={listItemTextStyleNumber}>
            4. Aceptación de las Condiciones
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
            Algunos servicios de la plataforma pueden requerir el registro del
            usuario. El usuario se compromete a proporcionar información veraz y
            completa durante el proceso de registro y a mantenerla actualizada.
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
            El usuario es responsable de mantener la confidencialidad de su
            nombre de usuario y contraseña, y de todas las actividades que
            ocurran bajo su cuenta. byNeural no será responsable de los daños o
            pérdidas derivados del incumplimiento de esta obligación por parte
            del usuario.
          </Typography>
          <Typography sx={listItemTextStyleNumber}>
            5. Propiedad Intelectual
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
            Todos los contenidos del sitio web, incluyendo, pero no limitado a,
            textos, imágenes, gráficos, logotipos, iconos, software y cualquier
            otro material, están protegidos por derechos de autor y otros
            derechos de propiedad intelectual. Estos contenidos son propiedad de
            byNeural o de sus licenciantes.
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
            Los usuarios no están autorizados a modificar, reproducir,
            distribuir, transmitir, exhibir, ejecutar, publicar, licenciar,
            crear trabajos derivados, transferir o vender ningún contenido del
            sitio web sin el consentimiento previo y por escrito de byNeural.
          </Typography>
          <Typography sx={listItemTextStyleNumber}>
            6. Limitación de responsabilidad
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
            byNeural no garantiza la disponibilidad, continuidad o infalibilidad
            del funcionamiento del sitio web y de la plataforma, y en
            consecuencia, excluye cualquier responsabilidad por los daños y
            perjuicios de cualquier naturaleza que puedan deberse a la falta de
            disponibilidad o de continuidad del funcionamiento del sitio y de
            los servicios habilitados en el mismo.
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
            byNeural no será responsable de los daños o perjuicios que pudieran
            derivarse del uso indebido de la plataforma por parte de los
            usuarios, incluyendo la violación de derechos de propiedad
            intelectual o de protección de datos.
          </Typography>
          <Typography sx={listItemTextStyleNumber}>
            7. Enlaces a Terceros
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
            El sitio web puede contener enlaces a sitios web de terceros que no
            son controlados por byNeural. No nos hacemos responsables del
            contenido de estos sitios ni de cualquier daño o pérdida que pueda
            surgir del uso de los mismos. El acceso a estos sitios web de
            terceros es bajo la responsabilidad del usuario.
          </Typography>
          <Typography sx={listItemTextStyleNumber}>
            8. Terminación del Servicio
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
            byNeural se reserva el derecho de suspender o terminar el acceso del
            usuario al sitio web y a la plataforma en cualquier momento, sin
            previo aviso, si considera que el usuario ha violado estas
            Condiciones de Uso o cualquier ley o regulación aplicable.
          </Typography>
          <Typography sx={listItemTextStyleNumber}>
            9. Ley Aplicable y Jurisdicción
          </Typography>
          <Typography sx={listItemTextStyleNumberNext}>
            Estas Condiciones de Uso se rigen por la legislación española.
            Cualquier disputa relacionada con el uso del sitio web y de los
            servicios será resuelta por los tribunales de Barcelona, España.
          </Typography>
          {/* --------------------------------------------------------------------------------------------------------------------- */}
          <Typography sx={subtitleStyle}>
            Protección de Datos Personales
          </Typography>
          <Typography sx={textStyle}>
            En cumplimiento del GDPR, informamos que los datos personales
            recabados a través de este sitio web serán tratados conforme a
            nuestra{" "}
            <Link
              href="/politicas-cuentas"
              underline="always"
              sx={{ color: colors.primary[500] }}
            >
              Política de Privacidad
            </Link>
            . Los usuarios tienen derecho a acceder, rectificar y eliminar sus
            datos personales, así como a otros derechos, dirigiéndose a
            privacy@byneural.ai.
          </Typography>

          {/* --------------------------------------------------------------------------------------------------------------------- */}
          <Typography sx={subtitleStyle}>Propiedad Intelectual</Typography>
          <Typography sx={textStyle}>
            Todos los contenidos de este sitio web, incluyendo textos, imágenes,
            diseños y códigos fuente, están protegidos por derechos de propiedad
            intelectual y son propiedad exclusiva de Israel Llagostera García o
            de terceros que han autorizado su uso.
          </Typography>
          {/* --------------------------------------------------------------------------------------------------------------------- */}
          <Typography sx={subtitleStyle}>
            Limitación de Responsabilidad
          </Typography>
          <Typography sx={textStyle}>
            Israel Llagostera García no se hace responsable de los posibles
            daños o perjuicios derivados del uso de este sitio web. No
            garantizamos la disponibilidad y continuidad del funcionamiento del
            sitio web.
          </Typography>
          {/* --------------------------------------------------------------------------------------------------------------------- */}
          <Typography sx={subtitleStyle}>Enlaces Externos</Typography>
          <Typography sx={textStyle}>
            Israel Llagostera García no se responsabiliza del contenido de los
            sitios web externos vinculados desde nuestro sitio.
          </Typography>
          {/* --------------------------------------------------------------------------------------------------------------------- */}
          <Typography sx={subtitleStyle}>
            Modificaciones del Aviso Legal
          </Typography>
          <Typography sx={textStyle}>
            Israel Llagostera García se reserva el derecho a modificar el
            presente Aviso Legal en cualquier momento. Las modificaciones serán
            notificadas a los usuarios por correo electrónico.
          </Typography>
          {/* --------------------------------------------------------------------------------------------------------------------- */}
          <Typography sx={subtitleStyle}>
            Jurisdicción y Ley Aplicable
          </Typography>
          <Typography sx={textStyle}>
            Cualquier controversia que se derive del uso de este sitio web será
            resuelta bajo la jurisdicción de los tribunales de Barcelona,
            España, y de acuerdo con la legislación española.
          </Typography>
          {/* --------------------------------------------------------------------------------------------------------------------- */}
        </Stack>
      </Box>
      <Box sx={{ mt: "5rem" }}>
        <Footer />
      </Box>
    </Box>
  );
};
