import * as React from "react";
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useScreenSize } from "../hooks/ScreenSizeContext";
import Divider from "@mui/material/Divider";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import { MediumTitleBox } from "./MediumTitleBox";
import extraImage from "../Data/Assets/Extra.png";

import Apariencia_video from "../Data/Assets/Images/Apariencia_video.mp4";
import Documentacion_video from "../Data/Assets/Images/Documentacion_video.mp4";
import Script from "../Data/Assets/Images/Script.jpg";
import Workflows_video from "../Data/Assets/Images/Workflows_video.mp4";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  marginTop: "1rem",
  marginBottom: "1rem",
  backgroundColor: "#fff",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
  boxShadow: "none",
}));

const AccordionSummary = styled(({ indexNumber, ...props }) => (
  <MuiAccordionSummary
    expandIcon={
      <Typography
        sx={{
          fontSize: "0.9rem",
          fontFamily: "inter",
          marginBottom: "-0.2rem",
          marginRight: "0.5rem",
          color: "black",
        }}
      >
        {indexNumber}
      </Typography>
    }
    {...props}
  />
))(({ theme }) => ({
  marginTop: "1rem",
  marginBottom: "0rem",
  backgroundColor: "#fff",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  borderBottom: "none",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  marginTop: "-1rem",
  padding: theme.spacing(2),
  backgroundColor: "#fff",
  borderTop: "none",
}));

export const AccordionComponent = () => {
  const [expanded, setExpanded] = React.useState("panel1");
  const [selectedImage, setSelectedImage] = React.useState(Documentacion_video);

  const handleChange = (panel, image) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    setSelectedImage(newExpanded ? image : extraImage);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { pagewidth } = useScreenSize();

 

  return (
    <Box
      sx={{
        width: "100%",
        height: "40rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Grid
        container
        sx={{
          maxWidth: `${pagewidth}px`,
        }}
      >
        <Grid item xs={12} md={4}>
          <Grid container>
            <Grid item xs={12}>
              <MediumTitleBox title="Configúralo en minutos." subtitle={""} />
            </Grid>

            <Grid item xs={12}>
              <div style={{ marginLeft: "0rem" }}>
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1", Documentacion_video)}
                  sx={{ backgroundColor: "transparent" }}
                >
                  <AccordionSummary
                    sx={{ backgroundColor: "transparent" }}
                    indexNumber="01"
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontFamily: "Inter",
                        fontWeight: "bold",
                        fontSize: { xs: "1rem", md: "1.125rem" },
                        color: colors.grey[700],
                      }}
                    >
                      Añade la Documentación
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ backgroundColor: "transparent" }}>
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontFamily: "Inter",
                        fontSize: { xs: "1rem", md: "1.125rem" },
                        color: colors.grey[500],
                      }}
                    >
                      Añade los archivos de información sobre el negocio o directamente añade enlaces para sacar la información de ahí.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Divider color={colors.grey[200]} />
                <Accordion
                  sx={{ backgroundColor: "transparent" }}
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2", Apariencia_video)}
                >
                  <AccordionSummary
                    sx={{ backgroundColor: "transparent" }}
                    indexNumber="02"
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                  >
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontFamily: "Inter",
                        fontWeight: "bold",
                        fontSize: { xs: "1rem", md: "1.125rem" },
                        color: colors.grey[700],
                      }}
                    >
                      Personalízalo
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ backgroundColor: "transparent" }}>
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontFamily: "Inter",
                        fontSize: { xs: "1rem", md: "1.125rem" },
                        color: colors.grey[500],
                      }}
                    >
                      Haz que el asistente virtual encaje a la perfección con la
                      identidad de marca de tu cliente. Personaliza los colores,
                      imágenes, animaciones, icono, etc.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Divider color={colors.grey[300]} />
                <Accordion
                  sx={{ backgroundColor: "transparent" }}
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3", Workflows_video)}
                >
                  <AccordionSummary
                    sx={{ backgroundColor: "transparent" }}
                    indexNumber="03"
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                  >
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontFamily: "Inter",
                        fontWeight: "bold",
                        fontSize: { xs: "1rem", md: "1.125rem" },
                        color: colors.grey[700],
                      }}
                    >
                      Configura los embudos de ventas
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ backgroundColor: "transparent" }}>
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontFamily: "Inter",
                        fontSize: { xs: "1rem", md: "1.125rem" },
                        color: colors.grey[500],
                      }}
                    >
                      Crea y configura todos los embudos de ventas que
                      necesites. Añade respuestas con vídeos, imágenes, recomendaciones de
                      productos, formularios, etc.

                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Divider color={colors.grey[200]} />
                <Accordion
                  sx={{ backgroundColor: "transparent" }}
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4", Script)}
                >
                  <AccordionSummary
                    sx={{ backgroundColor: "transparent" }}
                    indexNumber="04"
                    aria-controls="panel4d-content"
                    id="panel4d-header"
                  >
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontFamily: "Inter",
                        fontWeight: "bold",
                        fontSize: { xs: "1rem", md: "1.125rem" },
                        color: colors.grey[700],
                      }}
                    >
                      Añádelo a la web de tu cliente
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ backgroundColor: "transparent" }}>
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontFamily: "Inter",
                        fontSize: { xs: "1rem", md: "1.125rem" },
                        color: colors.grey[500],
                      }}
                    >
                      Solo tendrás que añadir un script en su página
                      web para poder integrarlo el asistente virtual personalizado.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Divider color={colors.grey[200]} />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              borderRadius: "1rem",
              width: "100%",

              height: "auto",
              mt: { xs: "3rem" },
              ml: "3rem",
            }}
          >
            {/* Mostrar imagen o video dinámicamente */}
            {selectedImage.includes(".mp4") ? (
              <video
                src={selectedImage}
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "1rem",
                }}
                autoPlay
                muted
                loop
              />
            ) : (
              <img
                src={selectedImage}
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "1rem",
                }}
                alt="dynamic content"
              />
            )}
          </Box>

          {/* Precargar imágenes y vídeos invisibles */}
          <div style={{ display: "none" }}>
            <video src={Documentacion_video} preload="auto" />
            <video src={Apariencia_video} preload="auto" />
            <video src={Workflows_video} preload="auto" />
            <img src={Script} alt="precarga script" />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
