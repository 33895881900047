import React from "react";
import { Button, Typography, Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import { useScreenSize } from "../hooks/ScreenSizeContext";

function CustomBtn({ variant = "primary", text, version }) {
  const { scrollToContact } = useScreenSize();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box sx={{ width: "100%" }}>
      <Button
        sx={{
          width: "100%",
          height: "100%",
          padding: {xs: "0.8rem 0.8rem 0.8rem 2.075rem",md:"1.125rem 0.8rem 1.125rem 2.075rem"},
          alignSelf: "center",
          background: variant === "primary" ? colors.primary[500] : "fff", // Color primario
          borderRadius: "0.75rem",
          color: variant === "primary" ? colors.grey[100] : colors.primary[500], // Cambios en el color del texto
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: `1px solid ${colors.primary[500]}`,
          boxShadow: "none",
          position: "relative", // Importante para la posición del ícono
          transition: "padding-left 300ms ease",
          "&:hover": {
            background:
              variant === "primary" ? colors.primary[500] : colors.grey[100],
            boxShadow: "none",
            pl: "1.575rem",
          },
          "&:hover .hover-icon": {
            opacity: 1, // Muestra el ícono en hover
            ml: "0.5rem",
            transition: "opacity 300ms ease, margin-left 300ms ease",
          },
        }}
        onClick={scrollToContact}
      >
        <Typography
          className="text-content"
          sx={{
            textTransform: "none",
            fontSize: {xs:"0.875rem",md:"1.125rem"},
            fontWeight: "700",
            fontFamily: "Inter",
            transition: "padding-left 300ms ease",
          }}
        >
          {text}
        </Typography>
        <EastRoundedIcon
          sx={{
            ml: "0rem",
            fontSize: "1.5rem", // Tamaño del ícono para hacerlo concordante con el texto
            color: "inherit", // El color del ícono será el mismo que el del texto
            right: 15, // Distancia desde el borde derecho del botón
            opacity: 0, // Inicialmente invisible
            transition: "opacity 300ms ease, margin-left 300ms ease", // Transición suave para la aparición y el desplazamiento
          }}
          className="hover-icon" // Clase para manejar el hover
        />
      </Button>
    </Box>
  );
}

export default CustomBtn;
