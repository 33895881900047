import React from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import OfferTitle from "./OfferTitle";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import { ServiceCard } from "./ServiceCard";
import { useScreenSize } from "../hooks/ScreenSizeContext";
import { MediumTitleBox } from "./MediumTitleBox";
import CustomDivider from "./CustomDivider";
import img3 from "../Data/Assets/Dashboard.png";
import img2 from "../Data/Assets/Multimedia-2.png";
import img1 from "../Data/Assets/Leads.png";
import CardFeature from "./CardFeature";

export const UseCasesBox = ({ variant }) => {
  const { screenSize, pagewidth } = useScreenSize();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex", // Habilita flexbox
        flexDirection: "column", // Organiza los hijos en columna
        justifyContent: "center", // Centra los hijos verticalmente
        alignItems: "center",
      }}
    >
      <Grid
        container
        sx={{
          alignContent: "center",
          alignItems: "flex-start",
          maxWidth: `${pagewidth}px`,
        }}
      >
        {/* Título */}
        <Grid item xs={12} sm={12} md={10} lg={8} sx={{ marginBottom: "2rem" }}>
          <Typography
            sx={{
              textAlign: "left",
              fontWeight: "800",
              fontSize: {
                xs: "1.75rem",
                sm: "2.25rem",
                md: "2.75rem",
                lg: "3rem",
              },
              fontFamily: "Inter",
              color: colors.grey[900],
            }}
          >
            Ayuda a tus clientes.
            <br />
            {isMobile ? (
              <><span style={{ color: colors.primary[500] }}>Diferénciate</span>.</>
            ) : (
              <>
                <span style={{ color: colors.primary[500] }}>
                  Diferénciate{" "}
                </span>{" "}
                de la competencia.
              </>
            )}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            alignItems="stretch" // Cambio a 'stretch' para que los Grid items llenen el contenedor+
            justifyContent="center"
            sx={{
              height: "100%",
              width: "100%",
              maxWidth: `${pagewidth}px`,
            }}
          >
            {/* Informes */}
            <Grid
              item
              xs={12}
              sm={8}
              md={8}
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                padding: {
                  xs: "0 0 1rem 0",
                  sm: "0 1rem 0 0",
                  md: "0 1rem 0 0",
                },
              }}
            >
              <CardFeature
                title="Informes y sugerencias"
                subtitle="Recibe informes de las conversaciones con sugerencias y posibles áreas de mejora sobre el negocio."
                imageName="Informes"
                variant={variant}
                padding_bottom="0"
              />
            </Grid>

            {/* Productos */}
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                padding: {
                  xs: "1rem 0 0 0",
                  sm: "0 0 0 1rem",
                  md: "0 0 0 1rem",
                },
              }}
            >
              <CardFeature
                title="Buscador inteligente"
                subtitle="Recomienda productos a los clientes en función de sus necesidades."
                imageName="Products"
                variant={variant}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Leads */}
        <Grid item xs={12} sx={{ marginTop: "2rem" }}>
          <Grid
            container
            alignItems="stretch" // Cambio a 'stretch' para que los Grid items llenen el contenedor+
            justifyContent="center"
            sx={{
              height: "100%",
              width: "100%",
              maxWidth: `${pagewidth}px`,
            }}
          >
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                padding: {
                  xs: "0 0 1rem 0",
                  sm: "0 1rem 0 0",
                  md: "0 1rem 0 0",
                },
              }}
            >
              <CardFeature
                title="Generación de leads"
                subtitle="Captura leads enriquecidos con el contexto de la conversación."
                imageName="Leads"
                variant={variant}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              md={8}
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                padding: {
                  xs: "2rem 0 0 0",
                  sm: "0 0 0 1rem",
                  md: "0 0 0 1rem",
                },
              }}
            >
              <Typography
                sx={{
                  color: "#bebebe",
                  fontFamily: "Inter",
                  fontSize: { xs: "1.75rem", md: "2.75rem" },
                  fontWeight: { xs: 700, sm: 700 },
                  textAlign: { xs: "left", md: "left" },
                  alignContent: "center",
                  height: "100%",
                }}
              >
                byNeural no es solo para{" "}
                <span style={{ color: "#000", fontWeight: 800 }}>
                  {" "}
                  crear chatbots
                </span>
                ,
                <br />
                es para{" "}
                <span style={{ color: "#000", fontWeight: 800 }}>
                  {" "}
                  aportar valor a tus clientes
                </span>
                .{/* <span style={{ color: "#bebebe" }}> */}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
