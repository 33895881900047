import React from "react";
import { Typography, Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import { useScreenSize } from "../hooks/ScreenSizeContext";

function TitleComponent({ title, subtitle }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { screenSize, pagewidth } = useScreenSize();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        maxWidth: `${pagewidth}px`,
        margin: "0 auto", // Centrar el contenedor en la página
      }}
    >
      <Typography
        fontSize= "2rem"
        sx={{
          color: colors.grey[900],
          fontWeight: "900",
          fontFamily: "Inter",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          color: colors.grey[400],
          fontSize: {xs: "1rem", md: "1.125rem"},
          fontFamily: "Inter",
          maxWidth: "40rem",
          margin: "0.625rem auto", // Centrar el texto dentro de su contenedor
        }}
      >
        {subtitle}
      </Typography>
    </Box>
  );
}

export default TitleComponent;

