// En /Components/Footer.js
import React from "react";
import { Box, Typography, Grid, IconButton, Stack } from "@mui/material";
import logo from "../Data/Assets/lLight.png";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { useScreenSize } from "../hooks/ScreenSizeContext";
import { useNavigate } from "react-router";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";

const Footer = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate(); // useNavigate hook
  const { screenSize } = useScreenSize();

  const determinePadding = () => {
    switch (screenSize) {
      case "md":
      case "lg":
        return "0rem 2rem 0rem 2rem";
      default:
        return "0rem 1rem 0rem 1rem";
    }
  };

  const { pagewidth } = useScreenSize();
  return (
    <Box
      sx={{
        width: "100%",
        alignContent: "center",
        alignItems: "center",
        alignSelf: "center",
        backgroundColor: "black",
        color: "white",
        padding: determinePadding(),
      }}
    >
      <Grid container sx={{ maxWidth: pagewidth, margin: "2rem auto" }}>
        {/* Logo */}
        <Grid item xs={12} md={4}>
          <Stack
            sx={{
              alignContent: { xs: "center", md: "left" },
              alignItems: { xs: "center", md: "left" },
              alignSelf: "center",
            }}
          >
            <Box sx={{ width: { xs: "8rem", md: "10rem" } }}>
              <img src={logo} style={{ width: "100%" }} alt="Logo" />
            </Box>
          </Stack>
        </Grid>
        <Grid
          container
          xs={12}
          md={8}
          columnSpacing={"1rem"}
          sx={{ mt: { xs: "2rem", md: 0 } }}
        >
          <Grid item xs={12} sm={4}>
            <Stack
              sx={{
                alignContent: {xs: "center", md: "left"},
                alignItems: {xs: "center", md: "left"},
                alignSelf: "center",
                mb:"2rem"
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "1rem", md: "1.125rem" },
                  fontFamily: "Inter",
                  fontWeight: "bold",
                  color: colors.grey[100],
                }}
              >
                Navegación
              </Typography>
              <Typography
                onClick={() => navigate("/")}
                sx={{
                  mt: "0.5rem",
                  fontSize: "1rem",
                  fontFamily: "Inter",
                  color: colors.grey[400],
                  cursor: "pointer",
                  "&:hover": {
                    color: colors.grey[100],
                  },
                }}
              >
                Inicio
              </Typography>
              {/* <Typography
                onClick={() => navigate("/precios")}
                sx={{
                  fontSize: "1rem",
                  fontFamily: "Inter",
                  color: colors.grey[400],
                  cursor: "pointer",
                  "&:hover": {
                    color: colors.grey[100],
                  },
                }}
              >
                Precios
              </Typography> */}
              <Typography
                onClick={() =>
                  window.open("https://www.byneural.ai/dashboard", "_blank")
                }
                sx={{
                  fontSize: "1rem",
                  fontFamily: "Inter",
                  color: colors.grey[400],
                  cursor: "pointer",
                  "&:hover": {
                    color: colors.grey[100],
                  },
                }}
              >
                Área para clientes
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Stack
              sx={{
                alignContent: {xs: "center", md: "left"},
                alignItems: {xs: "center", md: "left"},
                alignSelf: "center",
                mb:"2rem"
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "1rem", md: "1.125rem" },
                  fontFamily: "Inter",
                  fontWeight: "bold",
                  color: colors.grey[100],
                }}
              >
                Contacto
              </Typography>
              <Typography
                sx={{
                  mt: "0.5rem",
                  fontSize: "1rem",
                  fontFamily: "Inter",
                  color: colors.grey[400],
                  cursor: "pointer",
                  "&:hover": {
                    color: colors.grey[100],
                  },
                }}
              >
                <a
                  href="tel:+34608469208"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  +34 608469208
                </a>
              </Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontFamily: "Inter",
                  color: colors.grey[400],
                  cursor: "pointer",
                  "&:hover": {
                    color: colors.grey[100],
                  },
                }}
              >
                <a
                  href="mailto:info@byneural.ai"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  info@byneural.ai
                </a>
              </Typography>
              <Stack direction="row" sx={{ mt: "0.5rem" }}>
                <IconButton
                  sx={{
                    p: 0,
                    color: colors.grey[500], // Define el color normal aquí
                    "&:hover": {
                      color: colors.grey[100], // Define el color de hover aquí
                    },
                  }}
                >
                  <FaXTwitter />
                </IconButton>
                <IconButton
                  component="a"
                  href="https://www.linkedin.com/company/byneural-ai/"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    p: 0,
                    ml: "1rem",
                    color: colors.grey[500], // Define el color normal aquí
                    "&:hover": {
                      color: colors.grey[100], // Define el color de hover aquí
                    },
                  }}
                >
                  <FaLinkedin />
                </IconButton>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Stack
              sx={{
                alignContent: {xs: "center", md: "left"},
                alignItems: {xs: "center", md: "left"},
                alignSelf: {xs: "center", md: "left"},
                mb:"2rem"
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "1rem", md: "1.125rem" },
                  fontFamily: "Inter",
                  fontWeight: "bold",
                  color: colors.grey[100],
                }}
              >
                Políticas de Privacidad
              </Typography>
              <Typography
                onClick={() => navigate("/politicas-cuentas")}
                sx={{
                  mt: "0.5rem",
                  fontSize: "1rem",
                  fontFamily: "Inter",
                  color: colors.grey[400],
                  cursor: "pointer",
                  "&:hover": {
                    color: colors.grey[100],
                  },
                }}
              >
                Política de Privacidad Cuentas
              </Typography>
              <Typography
                onClick={() => navigate("/politicas-asistentes")}
                sx={{
                  fontSize: "1rem",
                  fontFamily: "Inter",
                  color: colors.grey[400],
                  cursor: "pointer",
                  "&:hover": {
                    color: colors.grey[100],
                  },
                }}
              >
                Política de Privacidad Asistentes
              </Typography>
              <Typography
                onClick={() => navigate("/aviso-legal")}
                sx={{
                  fontSize: "1rem",
                  fontFamily: "Inter",
                  color: colors.grey[400],
                  cursor: "pointer",
                  "&:hover": {
                    color: colors.grey[100],
                  },
                }}
              >
                Aviso Legal
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ mt: "4rem", mb: "1rem" }}>
        <Box
          sx={{
            alignContent: "center",
            alignItems: "center",
            alignSelf: "center",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "0.875rem",
              fontFamily: "Inter",
              color: colors.grey[500],
            }}
          >
            ©2024 byNeural AI. All rights reserved.
          </Typography>
        </Box>
      </Grid>
    </Box>
  );
};

export default Footer;
