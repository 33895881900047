import React from "react";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import { useScreenSize } from "../hooks/ScreenSizeContext";
import { Typography, Box } from "@mui/material";

const SmallButton = ({ text, isScrolled, variant }) => {
  const { scrollToContact } = useScreenSize();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        alignSelf: "center",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        p: "0.25rem 1rem",
        border: `1px solid ${colors.grey[900]}`,
        background: "transparent",
        cursor: "pointer",
        borderRadius: "75rem",
        color: colors.grey[900],
        transition:
          "color 0.3s ease, borderColor 0.3s ease, background 0.3s ease",
        "&:hover": {
          color: colors.grey[100],
          background: colors.primary[500],
          borderColor: colors.primary[500],
        },
      }}
      onClick={scrollToContact}
    >
      <Typography
        sx={{
          fontFamily: "Inter",
          fontWeight: "500",
          fontSize: {xs: "0.75rem", md: "0.875rem"},
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default SmallButton;
