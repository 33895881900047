import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ImageCarrousel.css";
import { useMediaQuery } from "@mui/material";

import AparienciaImage from "../Data/Assets/Images/Apariencia.jpg";
import ConversacionesImage from "../Data/Assets/Images/Conversaciones.jpg";
import InformesImage from "../Data/Assets/Images/Informes.jpg";
import WorkflowsImage from "../Data/Assets/Images/Workflows.jpg";

const ImageCarousel = () => {
  const [autoPlay, setAutoPlay] = useState(false);
  const sliderRef = React.useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const isSmScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isXsScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const images = [
    { src: WorkflowsImage, alt: "Workflows" },
    { src: AparienciaImage, alt: "Apariencia" },
    { src: InformesImage, alt: "Informes" },
    { src: ConversacionesImage, alt: "Conversaciones" },
  ];

  const settings = {
    dots: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: autoPlay,
    autoplaySpeed: 3000,
    draggable: false,
    centerMode: true,
    centerPadding: "10%", // Cambiar esto para mostrar los lados
    beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex),
  };

  const handleImageClick = (index) => {
    if (index > currentIndex) {
      sliderRef.current.slickNext();
    } else if (index < currentIndex) {
      sliderRef.current.slickPrev();
    }
  };

  return (
    <div className="carousel-container">
      <Slider ref={sliderRef} {...settings}>
        {images.map((image, index) => (
          <div
            key={index}
            className={`slide ${index === currentIndex ? "centered" : ""}`}
            onClick={() => handleImageClick(index)}
          >
            <img src={image.src} alt={image.alt} className="carousel-image" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageCarousel;
