import apiClient from "./api-client";
import { useQuery } from "@tanstack/react-query";

const postData = (endpoint, props, control) => {
    const{data} = useQuery({
        queryKey: ["getData", endpoint, control],
        queryFn: () => apiClient
          .post(endpoint, props)
          .then((res) => res.data),
        enabled: control>=1,
    })
    return {data};
}

export default postData