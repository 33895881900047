import React, { useState, useRef, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Stack,
  Box,
  Hidden,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import { useTheme } from "@emotion/react";
import { useNavigate, useLocation } from "react-router-dom";
import logo1 from "../Data/Assets/lDark.png";
import logo2 from "../Data/Assets/sDark.png";
import { useScreenSize } from "../hooks/ScreenSizeContext";
import { tokens } from "../theme";
import { ButtonTopBar } from "./ButtonTopBar";
import SmallButton from "./SmallButton";

const CustomAppBar = styled(AppBar)(
  ({ theme, pagewidth, isScrolled, isHidden, variant, colors }) => ({
    backgroundColor:
      !isScrolled ? "transparent" : "rgba(255, 255, 255, 0.7)",
    backdropFilter: isScrolled ? "blur(15px)" : "none",
    paddingRight: "0rem",
    position: "fixed",
    margin: "auto",
    width: "100%",
    borderBottom: isScrolled ? `1px solid #EDEDED` : "none",
    transition:
      "background-color 0.3s ease, border-bottom 0.3s ease, top 0.3s ease",
    top: !isHidden ? "0" : "-64px",
  })
);

const TopBar = ({ page, variant }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { pagewidth } = useScreenSize();
  const buttonRefs = useRef([]);
  const underlineRef = useRef(null);
  const lastScrollY = useRef(0);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleRedirect = () => {
    window.location.href = "https://byneural.ai/";
  };

  const handleOpenDashboard = () => {
    window.open("https://www.byneural.ai/dashboard", "_blank");
  };

  const buttons = [
    { text: "Inicio", url: "/" },
    { text: "Área de clientes", url: "", onClick: handleOpenDashboard },
  ];

  const activeIndex = buttons.findIndex((button) => button.url === page);
  const targetIndex = hoveredIndex !== null ? hoveredIndex : activeIndex;

  const isXsScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.pageYOffset;
      setIsScrolled(currentScrollY > 0);

      if (currentScrollY > lastScrollY.current) {
        setIsHidden(true);
      } else {
        setIsHidden(false);
      }
      lastScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (underlineRef.current && buttonRefs.current[targetIndex]) {
      const button = buttonRefs.current[targetIndex];
      const buttonRect = button.getBoundingClientRect();
      const containerRect = button.parentElement.getBoundingClientRect();
      underlineRef.current.style.left = `${
        buttonRect.left - containerRect.left
      }px`;
      underlineRef.current.style.width = `${buttonRect.width}px`;
    }
  }, [targetIndex, buttons.length]);

  useEffect(() => {
    scrollToTop();
  }, [location.pathname]);

  return (
    <>
      <CustomAppBar
        pagewidth={pagewidth}
        elevation={0}
        isScrolled={isScrolled}
        isHidden={isHidden}
        variant={variant}
        colors={colors}
      >
        <Toolbar sx={{ color: "transparent" }}>
          <Stack
            direction="row"
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              maxWidth: pagewidth,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <button
              onClick={handleRedirect}
              style={{
                background: "none",
                border: "none",
                padding: 0,
                cursor: "pointer",
              }}
            >
              <img
                src={isXsScreen ? logo2 : logo1}
                style={{
                  width: isXsScreen ? "2rem" : "8rem",
                  marginTop: isXsScreen ? "0.5rem" : "0rem",
                }}
                alt="Logo"
              />
            </button>

            <Box sx={{ position: "relative", display: "flex" }}>
              {buttons.map((button, index) => (
                <ButtonTopBar
                  key={button.url || index}
                  onClick={() => {
                    if (button.url) {
                      if (page !== button.url) {
                        navigate(button.url);
                      } else {
                        scrollToTop();
                      }
                    } else if (button.onClick) {
                      button.onClick();
                    }
                  }}
                  text={button.text}
                  url={button.url}
                  page={page}
                  index={index}
                  isScrolled={isScrolled}
                  variant={variant}
                  setHoveredIndex={setHoveredIndex}
                  ref={(el) => (buttonRefs.current[index] = el)}
                />
              ))}
              <Box
                ref={underlineRef}
                sx={{
                  position: "absolute",
                  bottom: 0,
                  height: "2px",
                  backgroundColor:
                    colors.grey[900],
                  transition: "left 0.3s ease, width 0.3s ease",
                }}
              />
            </Box>

            <SmallButton
              text="Contáctanos"
              isScrolled={isScrolled}
              variant={variant}
            />
          </Stack>
        </Toolbar>
      </CustomAppBar>
      <Toolbar />
    </>
  );
};

export default TopBar;
