import React from "react";
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import TopBar from "../Components/TopBar";
import TitleBox from "../Components/TitleBox";
import { useScreenSize } from "../hooks/ScreenSizeContext";
import { ServicesOfferedBox } from "../Components/ServicesOfferedBox";
import { ContactFormL } from "../Components/ContactFormL";
import fondoSvg from "../Data/Assets/hero.svg";
import Footer from "../Components/Footer";
import { AccordionComponent } from "../Components/AccordionComponent";
import { AccordionComponentMobile } from "../Components/AccordionComponentMobile";
import SecondTitleBox from "../Components/SecondTitleBox";
import { UseCasesBox } from "../Components/UseCasesBox";
import { BenefitsGridBox } from "../Components/BenefitsGridBox";
import TitleComponent from "../Components/TitleComponent";
import CardFeature from "../Components/CardFeature";
import DoubleCard from "../Components/DoubleCard";
import CustomDivider from "../Components/CustomDivider";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import PagesCarrousel from "../Components/PagesCarrousel";

import background11 from "../Data/Assets/Backgrounds/bbblurry.svg";
import background12 from "../Data/Assets/Backgrounds/bbblurry3.svg";

import ImageCarousel from "../Components/ImageCarrousel";
import BenefitsCarrousel from "../Components/BenefitsCarrousel";

export const MainPage = ({}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { screenSize, contactFormRef } = useScreenSize();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const determinePadding = () => {
    switch (screenSize) {
      case "xs":
        return "0rem 1rem";
      case "sm":
        return "0rem 2rem";
      case "md":
        return "0rem 2rem";
      case "lg":
        return "0rem 2rem";
      default:
        return "0rem 1rem";
    }
  };

  const handleMouseOver = (e) => {
    e.currentTarget.style.transform = "scale(1.1)";
  };

  const handleMouseOut = (e) => {
    e.currentTarget.style.transform = "scale(1)";
  };

  const isXsScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        alignItems: "center",
        overflowX: "hidden",
      }}
    >
      {/* Background */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: -10,
          width: "100vw",
          zIndex: -1,
        }}
      >
        <>
          <div
            style={{
              width: "100vw",
              height: "160rem",
              backgroundImage: isXsScreen
                ? `url(${background12})`
                : `url(${background11})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </>
      </div>

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: "0rem", width: "100%" }}
      >
        {/* Hero */}
        <Grid
          item
          xs={12}
          sx={{
            width: "100%",
            padding: determinePadding(),
            overflow: "hidden",
            marginTop: "0rem",
          }}
        >
          <TitleBox />
        </Grid>

        {/* Image Carrousel */}
        <Grid
          item
          alignItems={"center"}
          xs={12}
          mt={{ xs: "2rem", lg: "4rem" }}
        >
          <ImageCarousel />
        </Grid>

        {/* Accordion */}
        <Grid
          item
          align={"center"}
          xs={12}
          sx={{
            width: "100%",
            padding: determinePadding(),
            marginTop: { xs: "8rem" },
          }}
        >
          {!isMobile && <AccordionComponent />}
          {isMobile && <AccordionComponentMobile />}
        </Grid>

        {/* Use Cases */}
        <Grid
          item
          align={"center"}
          xs={12}
          sx={{
            width: "100%",
            padding: determinePadding(),
            mt: { xs: "0rem", lg: "1rem" },
          }}
        >
          <UseCasesBox />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            mt: { xs: "5rem", md: "7rem" },
            width: "100%",
            padding: determinePadding(),
          }}
        >
          <CustomDivider />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            mt: {xs:"1rem", md:"5rem"},
            display: "flex",
            justifyContent: "center",
            padding: determinePadding(),
          }}
        >
          <BenefitsCarrousel />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mt: "2rem", padding: determinePadding() }}
          ref={contactFormRef}
        >
          <CustomDivider />
        </Grid>
        <Grid
          item
          align={"center"}
          xs={12}
          sx={{
            width: "100%",
            marginTop: {xs:"4rem",md:"7rem"},
          }}
        >
          <ContactFormL />
        </Grid>
        <Grid item xs={12} sx={{ mt: "7rem" }}>
          <Footer />
        </Grid>
      </Grid>
    </Box>
  );
};
