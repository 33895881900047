import React from "react";
import {
  Box,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Stats from "../Data/Assets/stats.png";
import Informeslanding from "../Data/Assets/Informes_landing.png";
import Apariencia from "../Data/Assets/Apariencia.png";
import Conversacion from "../Data/Assets/conversacion.png";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: "Estadísticas",
    imgPath: Stats,
  },
  {
    label: "Conversaciones",
    imgPath: Informeslanding,
  },
  {
    label: "Configuración",
    imgPath: Apariencia,
  },
  {
    label: "Personalización",
    imgPath: Conversacion,
  },
];

function PagesCarrousel() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box
      sx={{
        mt: 0,
        width: "100%",
        p: 0,
      }}
    >
      <Stack
        alignItems="center"
        justifyItems="center"
        maxWidth={"80rem"}
        width={{ xs: "90%" }}
      >
        <Box sx={{ width: "100%", flexGrow: 1 }}>
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            interval={4000}
            enableMouseEvents
          >
            {images.map((step, index) => (
              <div key={step.label}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Box
                    component="img"
                    sx={{
                      display: "block",
                      overflow: "hidden",
                      width: "100%",
                      border: "1px solid",
                      borderColor: theme.palette.grey[300],
                      borderRadius: "1.5rem",
                      mb: "1rem",
                    }}
                    src={step.imgPath}
                    alt={step.label}
                  />
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
        </Box>
      </Stack>
    </Box>
  );
}

export default PagesCarrousel;
