import React, { forwardRef } from "react";
import { Typography, Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";

export const ButtonTopBar = forwardRef(
  ({ onClick, text, url, page, index, setHoveredIndex, isScrolled, variant }, ref) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isActive = url === page;

    return (
      <Button
        ref={ref}
        onClick={onClick}
        onMouseEnter={() => setHoveredIndex(index)}
        onMouseLeave={() => setHoveredIndex(null)}
        sx={{
          margin: "0 0.5rem",
          position: "relative",
          height: "2rem",
          borderRadius: "0rem",
          fontWeight: "550",
          display: "flex",
          alignItems: "center",
          padding: "1.125rem 1rem",
          textTransform: "none",
          color: isActive ? colors.grey[900] : colors.grey[600],
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: "transparent",
            color: colors.grey[900],
          },
        }}
      >
        <Typography
          sx={{
            fontSize: {xs: "0.875rem", md: "1rem"},
            fontFamily: "Inter",
            "&:hover": {
              backgroundColor: "transparent",
              color: colors.grey[900],
            },
          }}
        >
          {text}
        </Typography>
      </Button>
    );
  }
);
