import * as React from "react";
import { Box, Typography, Divider } from "@mui/material";
import extraImage from "../Data/Assets/Extra.png";
import Apariencia_video from "../Data/Assets/Images/Apariencia_video.mp4";
import Documentacion_video from "../Data/Assets/Images/Documentacion_video.mp4";
import Script from "../Data/Assets/Images/scriptMobile.png";
import Workflows_video from "../Data/Assets/Images/Workflows_video.mp4";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";

export const AccordionComponentMobile = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const sections = [
    {
      title: "01. Añade la Documentación",
      text: "Añade los archivos de información sobre el negocio o directamente añade enlaces para sacar la información de ahí.",
      image: Documentacion_video,
    },
    {
      title: "02. Personalízalo",
      text: "Haz que el asistente virtual encaje a la perfección con la identidad de marca de tu cliente. Personaliza los colores, imágenes, animaciones, icono, etc.",
      image: Apariencia_video,
    },
    {
      title: "03. Configura los embudos de ventas",
      text: "Crea y configura todos los embudos de ventas que necesites. Añade respuestas con vídeos, imágenes, recomendaciones de productos, formularios, etc.",
      image: Workflows_video,
    },
    {
      title: "04. Añádelo a la web de tu cliente",
      text: "Solo tendrás que añadir un script en su página web para integrarle el asistente virtual personalizado.",
      image: Script,
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      
        <Typography
          sx={{
            textAlign: "left",
            fontWeight: "800",
            fontSize: {
              xs: "1.75rem",
              sm: "2.25rem",
              md: "2.75rem",
              lg: "3rem",
            },
            fontFamily: "Inter",
                  color: colors.grey[900],
            mb: "2rem",
          }}
        >
          Confíguralo en
          <span style={{ color: colors.primary[500] }}> minutos</span>.
        </Typography>
      
      {sections.map((section, index) => (
        <Box key={index} sx={{ marginBottom: "3rem" }}>
          <Typography
            sx={{
              textAlign: "left",
              fontWeight: "bold",
              fontFamily: "Inter",
              fontSize: "1rem",
              marginBottom: "0.5rem",
            }}
          >
            {section.title}
          </Typography>
          <Typography
            sx={{
              textAlign: "left",
              fontFamily: "Inter",
              fontSize: "0.875rem",
              color: "#555",
              marginBottom: "2rem",
            }}
          >
            {section.text}
          </Typography>

          {/* Mostrar imagen o video dinámicamente */}
          {section.image.includes(".mp4") ? (
            <video
              src={section.image}
              style={{ width: "100%", height: "auto", borderRadius: "0.5rem", marginBottom: "1rem" }}
              autoPlay
              muted
              loop
            />
          ) : (
            <img
              src={section.image}
              alt={section.title}
              style={{ width: "100%", height: "auto", borderRadius: "0.5rem"}}
            />
          )}
          {index < sections.length - 1 && <Divider sx={{ marginY: "1rem" }} />}
        </Box>
      ))}
    </Box>
  );
};
