import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import { useScreenSize } from "../hooks/ScreenSizeContext";

export const MediumTitleBox = ({ title, subtitle }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { screenSize } = useScreenSize();

  return (
    <Box>
      <Stack>
        <Typography
          sx={{
            textAlign: "left",
            fontWeight: "800",
            fontSize: {xs: "1.75rem", md: "2rem"},
            fontFamily: "Inter",
            color: colors.grey[900],
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            fontSize: {xs: "1rem", md: "1.125rem"},
            fontFamily: "inter",
            color: colors.grey[500],
            marginBottom: "0rem",
            mt: "0.25rem",
          }}
        >
          {subtitle}
        </Typography>
      </Stack>
    </Box>
  );
};
