import React from "react";
import { Typography, Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import { useScreenSize } from "../hooks/ScreenSizeContext";

// Importando imágenes
import Informes from "../Data/Assets/Images/Informes.png";
import Products from "../Data/Assets/Images/Productos_phone.png";
import Leads from "../Data/Assets/Images/Leads.png";

// Mapeo de nombres a imágenes
const images = {
  Informes: Informes,
  Products: Products,
  Leads: Leads,
};

function CardFeature({ title, subtitle, imageName, padding_bottom = "0rem" }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { screenSize } = useScreenSize();

  // Obtener la imagen correspondiente basada en el nombre
  const imgPath = images[imageName];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between", // Asegurar que el contenido se distribuya entre la parte superior e inferior
        alignItems: "center",
        pt: { xs: "2rem", sm: "1.5rem", lg: "3rem" },
        pr: { xs: "1.5rem", sm: "0.75rem", lg: "3rem" },
        pb: padding_bottom,
        pl: { xs: "1.5rem", sm: "0.75rem", lg: "3rem" },
        backgroundColor: "#f5f5f9",
        borderRadius: { xs: "1rem", md: "1.875rem" },
        height: "100%",
        transition: "transform 0.3s ease-in-out",
        backdropFilter: "blur(10px)",
        "&:hover": {
          transform: "scale(1.025)",
        },
        boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Typography
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            fontSize: { xs: "1.25rem", sm: "1rem", md: "1.5rem" },
            fontFamily: "Inter",
            color: colors.grey[900],
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            mt: "0.5rem",
            textAlign: "center",
            color: colors.grey[500],
            fontSize: "0.875rem",
            fontFamily: "Inter",
          }}
        >
          {subtitle}
        </Typography>
      </Box>

      {/* Caja para la imagen */}
      <Box
        sx={{
          width: "100%", // Ocupa todo el ancho del contenedor
          mt: "1rem",
          display: "flex",
          justifyContent: "center", // Centrar la imagen horizontalmente
          alignItems: "flex-end", // Mantener la imagen pegada abajo
        }}
      >
        <img
          src={imgPath}
          alt={title}
          style={{
            width: "100%", // La imagen ocupa el ancho disponible
            height: "auto", // Mantener la relación de aspecto
            borderRadius: "0.75rem",
            objectFit: "contain", // Mantener la imagen completa dentro del contenedor sin deformarse
          }}
        />
      </Box>
    </Box>
  );
}

export default CardFeature;
