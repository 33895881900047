import React from "react";
import Box from "@mui/material/Box";
import { useScreenSize } from "../hooks/ScreenSizeContext";

const CustomDivider = ({ marginTop, marginBottom }) => {
  const { screenSize, pagewidth } = useScreenSize();
  return (
    <Box
      sx={{
        mr: "auto",
        ml: "auto",
        height: "4px",
        width: "100%",
        maxWidth: `${pagewidth}px`,
        background: "linear-gradient(90deg, white 0%, #4541FF 50%, white 100%)",
      }}
    />
  );
};

export default CustomDivider;
