import React from "react";
import { CssBaseline, ThemeProvider, Box } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { MainPage } from "./scenes/MainPage";
import { ScreenSizeProvider } from "./hooks/ScreenSizeContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Prices } from "./scenes/Prices";
import { AccountPolitics } from "./scenes/AccountPolitics";
import { AssistantPolitics } from "./scenes/AssistantPolitics";
import { AvisoLegal } from "./scenes/AvisoLegal";
import TopBar from "./Components/TopBar";
import Page404 from "./scenes/Page404";

function App() {

  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ScreenSizeProvider>
          <Router>
            <Routes>
              <Route
                path="/"
                element={
                  <Box>
                    <TopBar page="/" />
                    <MainPage />
                  </Box>
                }
              />
              <Route
                path="/precios"
                element={
                  <Box>
                    <TopBar page="/precios" />
                    <Prices />
                  </Box>
                }
              />
              <Route
                path="/politicas-asistentes"
                element={
                  <Box>
                    <TopBar page="/politicas-asistentes" />
                    <AssistantPolitics />
                  </Box>
                }
              />
              <Route
                path="/politicas-cuentas"
                element={
                  <Box>
                    <TopBar page="/politicas-cuentas" />
                    <AccountPolitics />
                  </Box>
                }
              />
              <Route
                path="/aviso-legal"
                element={
                  <Box>
                    <TopBar page="/aviso-legal" />
                    <AvisoLegal />
                  </Box>
                }
              />
              <Route path="*" element={<Page404 />} />
            </Routes>
          </Router>
        </ScreenSizeProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
