import React from "react";
import { Box, Grid } from "@mui/material";
import { useScreenSize } from "../hooks/ScreenSizeContext";
import TitleComponent from "../Components/TitleComponent";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import PricingCard from "../Components/PricingCard";
import Footer from "../Components/Footer";

export const Prices = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { screenSize, pagewidth } = useScreenSize();

  const planDetails1 = {
    planName: "Gratis",
    price: 0,
    items: [
      "50 mensajes por mes",
      "No es necesario tarjeta de crédito",
      "Todas las funcionalidades de los otros planes",
    ],
  };
  const planDetails2 = {
    planName: "Básico",
    price: 27,
    items: [
      "1.000 mensajes por mes",
      "Estadísticas básicas",
      "Botón de Contacto",
      "Personalización completa de la interfaz",
      "Historial de conversaciones",
      "Webs ilimitadas",
      "Solo responde en 1 idioma",
    ],
  };
  const planDetails3 = {
    planName: "Pro",
    price: 52,
    items: [
      "5.000 mensajes por mes",
      "Estadísticas avanzadas",
      "Widgets avanzados",
      "Personalización completa de la interfaz",
      "Historial de conversaciones",
      "Webs ilimitadas",
      "Responde en más de 85 idiomas",
      "Leads",
      "Quitar marca de agua",
    ],
    highlight: true,
  };

  const determinePadding = () => {
    switch (screenSize) {
      case "md":
        return "0rem 2rem 0rem 2rem";
      case "lg":
        return "0rem 2rem 0rem 2rem";
      default:
        return "0rem 1rem 0rem 1rem";
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Grid
        container
        alignItems="stretch"
        justifyContent="center"
        sx={{
          marginTop: "0rem",
          width: "100%",
          maxWidth: `${pagewidth}px`,
          padding: determinePadding(),
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            width: "100%",
            overflow: "hidden",
            marginTop: "3rem",
          }}
        >
          <TitleComponent
            title={
              <span>
                Escoge el{" "}
                <span style={{ color: colors.primary[500] }}>plan</span> que se
                adapte a tus<br></br>
                <span style={{ color: colors.primary[500] }}>necesidades</span>
              </span>
            }
            subtitle={
              "Empieza gratis o elige el plan que más se adapte a ti y tus objetivos."
            }
          />
        </Grid>
        <Grid container justifyContent="center" sx={{ mt: "2rem" }} spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <PricingCard
              planName={planDetails1.planName}
              price={planDetails1.price}
              items={planDetails1.items}
              highlight={planDetails1.highlight}
              variant="secondary"
              bold={1}
              sx={{ flexGrow: 1 }} // Asegurar que el card ocupe toda la altura disponible
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <PricingCard
              planName={planDetails2.planName}
              price={planDetails2.price}
              items={planDetails2.items}
              highlight={planDetails2.highlight}
              variant="secondary"
              sx={{ flexGrow: 1 }} // Asegurar que el card ocupe toda la altura disponible
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={4}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <PricingCard
              planName={planDetails3.planName}
              price={planDetails3.price}
              items={planDetails3.items}
              highlight={planDetails3.highlight}
              variant="primary"
              sx={{ flexGrow: 1 }} // Asegurar que el card ocupe toda la altura disponible
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ width: "100%", mt: "5rem" }}>
        <Footer />
      </Grid>
    </Box>
  );
};
