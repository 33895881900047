import React, { useState } from "react";
import "./BenefitsCarrousel.css";
import { PiArrowCircleLeft, PiArrowCircleRight } from "react-icons/pi";
import { useMediaQuery } from "@mui/material";

// Imágenes
import Multiidioma from "../Data/Assets/Images/Multiidioma.png";
import Hubspot from "../Data/Assets/Images/Hubspot.png";
import Vídeo from "../Data/Assets/Images/Vídeo.png";
import OpenAI from "../Data/Assets/Images/OpenAI.png";
import Make from "../Data/Assets/Images/Make.png";

const BenefitsCarrousel = () => {
  const [indiceActual, setIndiceActual] = useState(0);

  // Detectar el tamaño de la pantalla
  const isSmallScreen = useMediaQuery("(max-width:600px)"); // Para xs y sm

  // Ajustar el número de imágenes visibles en función del tamaño de la pantalla
  const visibleCajas = isSmallScreen ? 1 : 3;

  const cajas = [
    { id: 1, texto: "Envía imágenes y vídeos", imagen: Vídeo },
    { id: 2, texto: "Leads integrados con Hubspot", imagen: Hubspot },
    { id: 3, texto: "Integración con Make", imagen: Make },
    { id: 4, texto: "Responde en múltiples idiomas", imagen: Multiidioma },
    { id: 5, texto: "Modelos de OpenAI", imagen: OpenAI },
  ];

  const siguiente = () => {
    if (indiceActual < cajas.length - visibleCajas) {
      setIndiceActual(indiceActual + 1);
    }
  };

  const anterior = () => {
    if (indiceActual > 0) {
      setIndiceActual(indiceActual - 1);
    }
  };

  return (
    <div className="carousel-contenedor">
      <button
        className="flecha flecha-izquierda"
        onClick={anterior}
        disabled={indiceActual === 0}
      >
        <PiArrowCircleLeft />
      </button>
      <div className="carousel-viewport">
        <div
          className="carousel-contenido"
          style={{
            transform: `translateX(-${indiceActual * (100 / visibleCajas)}%)`,
          }}
        >
          {cajas.map((caja, index) => (
            <div
              key={index}
              className="carousel-caja"
              style={{ backgroundImage: `url(${caja.imagen})` }}
            >
              <div className="caja-texto">{caja.texto}</div>
            </div>
          ))}
        </div>
      </div>
      <div onClick={siguiente}>
        <button
          className="flecha flecha-derecha"
          disabled={indiceActual >= cajas.length - visibleCajas}
        >
          <PiArrowCircleRight />
        </button>
      </div>
    </div>
  );
};

export default BenefitsCarrousel;
