import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { tokens } from "../theme";
import CustomButton from "./CustomBtn";

const PricingCard = ({ planName, price, items, highlight, variant, sx, bold = 0 }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: highlight ? colors.primary[500] : colors.grey[200],
        borderRadius: "2rem",
        textAlign: "center",
        padding: { xs: "3rem 1.5rem", md: "3rem" },
        height: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        ...sx,
      }}
    >
      {highlight && (
        <Box
          sx={{
            position: "absolute",
            top: "-10px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: colors.primary[500],
            color: "#fff",
            padding: "0.2rem 1rem",
            borderRadius: "8px",
            fontSize: "0.875rem",
            fontWeight: "bold",
          }}
        >
          Mejor oferta
        </Box>
      )}
      <Typography
        sx={{
          fontSize: { xs: "1.25rem", md: "2rem" },
          fontFamily: "Inter",
        }}
      >
        {planName}
      </Typography>
      <Typography
        sx={{
          fontFamily: "Inter",
          fontWeight: "bold",
          fontSize: { xs: "3rem", md: "4rem" },
          p: 0,
          m: 0,
        }}
      >
        {price}€
        <span style={{ fontSize: "1rem", fontWeight: "bold" }}>/mes</span>
      </Typography>
      <List
        sx={{
          pb: "3rem",
          flexGrow: 1,
          textAlign: { xs: "center", md: "left" }, // Centrar en pantallas pequeñas y alinear a la izquierda en pantallas grandes
        }}
      >
        {items.map((item, index) => (
          <ListItem
            key={index}
            sx={{
              padding: "0.125rem 0",
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" }, // Centrar en pantallas pequeñas y alinear a la izquierda en pantallas grandes
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "center", md: "flex-start" },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: "auto",
                  marginRight: "0.5rem",
                }}
              >
                <CheckIcon sx={{ color: colors.grey[900] }} />
              </ListItemIcon>
              <ListItemText
                primary={item}
                primaryTypographyProps={{
                  sx: {
                    fontSize: {
                      xs: "0.875rem", // Tamaño de fuente para pantallas pequeñas-medianas
                      lg: "1rem", // Tamaño de fuente para pantallas grandes
                    },
                    color: colors.grey[900],
                    fontFamily: "Inter",
                    textAlign: { xs: "center", md: "left" },
                    fontWeight: index === items.length - 1 && bold  ? "bold" : "normal", // Aplicar negrita al último ítem
                  },
                }}
              />
            </Box>
          </ListItem>
        ))}
      </List>
      <Box sx={{ mt: "auto", display: "flex", justifyContent: "center" }}>
        <CustomButton variant={variant} text="Empezar ahora" />
      </Box>
    </Box>
  );
};

export default PricingCard;

